.problems-logo-number
  color: white
  position: absolute
  left: 20px
  top: 14px
  font-size: 20px
  margin-left: 10px

.problems-logo-counter
  width: 45px
  margin-left: 10px

.center
  text-align: center

.step
  padding: 20px 0

.nav-logo
  margin-top: 13.5px

.decision-text
  margin-top: 9.5px

.feature-list-item
  font-weight: 400
  padding: 0.8em 0

.mobile-app-link
  max-width: 60%

.subfooter-copyrights-text-brand-name
  color: white

.footer-social-links
  margin-top: -7.5px
  display: inline-block

.start-work-btn
  color: white
  background-color: $blue-bright
  border-radius: 5px
  padding: 15px 30px
  text-decoration: none
  transition: box-shadow 0.2s ease, background 0.2s ease
  box-shadow: 0 2px 3px rgba(#000, 0.1)
  &:hover
    background-color: adjust-hue($blue-bright, 10%)
    box-shadow: 0 4px 8px rgba(#000, 0.2)

.start-work-block
  margin-top: 50px

@media screen and (max-width: 991px)
  .problems-logo-number
    color: white
    position: absolute
    left: 19px
    top: 13px
    font-size: 24px

  .decision-text
    margin-top: 1.5px

  .footer-social-links
    margin-top: 7.5px

@media screen and (max-width: 767px)
  .problems-logo-number
    color: white
    top: 15px
    font-size: 20px
    text-align: center
    width: 100%
    left: -12px

  .nav-logo
    max-width: 65%
    margin-top: 10.5px

  .mobile-app-link
    max-width: 40%
